<template>
  <div id="license-music">
    <Navbar class="white-text light-menu-icon" />

    <div class="background">
      <img
        src="@/assets/background-top-02.svg"
        id="mlc-bg"
        alt="background image"
      />
    </div>

    <section id="power-your-business">
      <h1 class="page-title">HFA & The MLC</h1>
      <p>
        As of January 1, 2021, The Mechanical Licensing Collective (The MLC)
        officially began administering a new blanket license, created pursuant
        to the Music Modernization Act of 2018, that will be available to
        digital audio services operating in the United States. The MLC is
        responsible for collecting the mechanical royalties due under the
        blanket license from those digital services and then distributing those
        mechanical royalties to music publishers, administrators,
        self-administered songwriters and others who are entitled to receive
        them.
      </p>
      <p>
        HFA is proud to be one of The MLC’s vendors and was engaged to help The
        MLC prepare for the implementation of this historic and important new
        licensing regime, which will change the way digital audio mechanical
        royalties will be administered in the U.S. In that capacity, HFA is
        helping The MLC by:
      </p>
      <ul>
        <li>
          Sharing HFA’s existing musical works data with The MLC, which The MLC
          has used as a starting point for establishing its new public musical
          works database;
        </li>
        <li>
          Offering owners and administrators of musical works the opportunity to
          “opt-in” and share their existing HFA Account Information with The
          MLC, so that owners and administrators can seamlessly set up their new
          MLC Member Profiles using information previously provided to HFA; and
        </li>
        <li>
          Providing support and systems development services to The MLC to help
          The MLC stand-up its data matching and royalty distribution processes.
        </li>
      </ul>
      <p>
        In February, The MLC expects to begin receiving its first usage
        reporting files for the period ending January from digital audio
        services that elect to operate under the new blanket license. With that
        information, The MLC will begin work on preparing the first monthly
        royalty distribution for its members. In addition, in that same month,
        The MLC will receive from the services their historic unmatched usage
        information and accrued but undistributed royalties.
      </p>
      <p>
        DON’T FORGET: You need to become a member of The MLC in order to receive
        royalty payments from The MLC. You can begin the membership process by
        clicking on the “Connect to Collect” button on the homepage of
        <a href="https://www.themlc.com/" target="_blank"
          >The MLC’s official website</a
        >. Or, if you have an existing HFA Online Account and have not done so
        already, you can use the
        <a
          href="https://portal.harryfox.com/auth/login/?return=%2Fagreement_portal"
          target="_blank"
          >HFA Agreement Portal</a
        >
        to begin The MLC’s membership process by authorizing HFA to transfer
        your Account Information (including your current contact, payment, and
        tax information) to The MLC.
      </p>
      <p>
        For more information about The MLC and the membership process, please
        visit <a href="https://www.themlc.com/" target="_blank">TheMLC.com</a>
      </p>
      <img
        src="@/assets/power-your-business.png"
        alt="Power your business with Rumblefish"
      />
    </section>
    <Footer />
  </div>
</template>

<script>
import Consultation from "@/views/Consultation.vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "LicenseMusic",
  components: {
    Navbar,
    Footer
  },
  metaInfo: function() {
    return {
      title: " - HFA and The MLC",
      meta: [
        {
          name: "description",
          content:
            "HFA is proud to be one of The MLC's vendors and was engaged to help The MLC prepare for the implementation of this historic and important new licensing regime"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  methods: {
    showConsultationForm: function() {
      this.$modal.show(
        Consultation,
        {
          router: this.$router
        },
        {
          height: "auto",
          width: "80%",
          classes: "v--modal contact-form-modal"
        }
      );
    }
  },
  mounted: function() {
    if (window.innerWidth <= 1224) {
      const content = document.getElementById("power-your-business");

      setTimeout(() => {
        document.getElementById("mlc-bg").style.height =
          content.offsetHeight +
          parseFloat(window.getComputedStyle(content)["margin-top"]) +
          "px";
      }, 100);
    }
  }
};
</script>

<style lang="scss">
#license-music {
  > .background {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;
    overflow: hidden;
    width: 100vw;

    img {
      height: 560vw;
      transform: translate(-75%, 0%);
    }

    @media (min-width: $desktop) {
      overflow: visible;
      width: auto;

      img {
        width: 69.986vw;
        height: auto;
        transform: none;
      }
    }

    &.bg-02 {
      top: initial;
      right: initial;
      left: 0;
      width: 100vw;
      overflow: hidden;
      transform: translateY(-5%);

      img {
        width: 300vw;
        transform: translateX(-35%);
      }

      @media (min-width: $desktop) {
        top: initial;
        right: initial;
        left: 0;
        width: 100vw;
        overflow: hidden;
        height: 119vw;
        transform: translateY(-22%);

        img {
          width: 135vw;
          transform: translateX(-20%);
        }
      }
      // @media (min-height)
    }
  }

  section {
    margin-bottom: 20vh;

    @media (min-width: $desktop) {
      margin-bottom: 20vh;
    }
  }

  #power-your-business {
    margin-top: 5vh;

    h1,
    p,
    ul {
      color: #fff;
    }
    h1 {
      font-size: 1.75em;
      letter-spacing: 0.2px;
      margin-top: 10vw;
      margin-bottom: 3.001vw;
    }
    p,
    ul {
      font-size: 1em;
      line-height: 1.89;
      width: auto;
    }
    > img {
      position: relative;
      display: block;
      width: 80%;
      margin: -60px auto 10px;
      z-index: -1;
    }
    a {
      text-decoration: underline;
      color: blue;
    }
    .clients {
      margin: 50px 0 0;

      h2 {
        margin: 0;
        text-align: center;
        font-size: 1.3em;
      }
      img {
        width: 100%;
        margin: 0;
        z-index: 1;
      }
    }

    @media (min-width: $desktop) {
      margin-top: 10vh;

      h1,
      p,
      ul {
        color: #000;
      }
      h1 {
        font-size: 2.25em;
      }
      p,
      ul {
        font-size: 1.1875em;
        width: 487px;
      }
      > img {
        position: absolute;
        top: 0;
        right: 6.882vw;
        width: 43.266vw;
        margin: 0;
      }
    }
    @media (min-height: 550px) {
      margin-top: 15vh;
    }
  }

  @media (max-width: $mobile) {
    #navbar {
      .logo {
        img {
          filter: invert(100%);
        }
      }
    }
  }
}
</style>
